
/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace SlideShowCarousel Component instance
 * @author Naman
 * @class SlideShowCarousel
 * @classdesc Ace SofitelSlideShowCarousel to inherit the SlideShowCarousel core component
 * @extends SlideShowCarousel
 * @version 1.0
 */
import { SlideShowCarousel } from '@accor/ace-ui-core';

// Assume CoreJS is available and ResponsiveConstants are defined

export default class SofitelSlideShowCarousel extends SlideShowCarousel {
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  initialize() {
    super.initialize();
    this._colContainerVal = this.componentHost.querySelector('.cmp-carouselslideshow__content');

    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.disablePaginationLoop();
    }
  }

  disablePaginationLoop() {
    this._active = 1;
    const total = this.componentHost.querySelectorAll('.cmp-carouselslideshow__content .cmp-carouselslideshow__item')?.length;
    const controls = this.componentHost.querySelector('.cmp-carouselslideshow__actions');
    const previousButton = controls.querySelector('.cmp-carouselslideshow__action--previous');
    const nextButton = controls.querySelector('.cmp-carouselslideshow__action--next');
    const itemList = this._colContainerVal.querySelectorAll('.cmp-carouselslideshow__item');

    const thresholdDefinition = {
      threshold: 0.6
    };
    this.specialTouchObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._active = parseInt(entry.target.getAttribute('display-id'));
            if (this._active === 1) previousButton?.classList?.add('disabled');
            if (this._active < total) nextButton?.classList?.remove('disabled');
            if (this._active > 1) previousButton?.classList?.remove('disabled');
            if (this._active === total) nextButton?.classList?.add('disabled');
            previousButton.disabled = null;
            nextButton.disabled = null;
            itemList[this._active - 1]?.removeAttribute('aria-hidden');
            itemList[this._active - 1]?.classList?.add('cmp-carouselslideshow__item--active');
          }
        }
      });
    }, thresholdDefinition);


    itemList?.forEach((item, index) => {
      item.setAttribute('display-id', index + 1);
      this.specialTouchObserver?.observe(item);
    });

    previousButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this._colContainerVal.scrollLeft -= this._colContainerVal.querySelector('.cmp-carouselslideshow__item')?.offsetWidth;
    });
    nextButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this._colContainerVal.scrollLeft += this._colContainerVal.querySelector('.cmp-carouselslideshow__item')?.offsetWidth;
    });
  }
}


// Register the SlideShow component with the BaseComponent
CoreJS.BaseComponent.registerComponent(SlideShowCarousel.CLASS_NAMESPACE, SofitelSlideShowCarousel, true);
