/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Loyalty Component instance
 * @author Rishabh
 * @class IbisHeading
 * @classdesc Ibis Heading component to inherit the Heading core component
 * @extends Heading
 * @version 1.0
 */
import { Heading, TagManager } from '@accor/ace-ui-core';

export default class IbisHeading extends Heading {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }
    this.headingCta = this.componentHost.querySelectorAll('.ace-headingpagehero-headline a');
    if (this.dataLayerJson?.pageName === 'ibis::go get it::ibis family' || this.dataLayerJson?.pageName === 'ibis::go get it::ibis'
        ||  this.dataLayerJson?.pageName === 'ibis::go get it::ibis styles' || this.dataLayerJson?.pageName === 'ibis::go get it::ibis budget') {
          this.TagManagerHandler();
        }
  }

  /**
     * Push data in tagmanager
     */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.headingCta?.forEach((element) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          this.dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: 'main cta',
              bloc_interaction: element?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(this.dataEvent);
        });
      });
    }
  }
}


// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Heading.CLASS_NAMESPACE, IbisHeading, true);
