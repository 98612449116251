// Stylesheets
import './theme.scss';
import './resources/images/favicon.svg';

// ACE Library JS
import "@accor/ace-ui-core";

// Import here all JS overlays that are overrigind ace-ui-core library. By default, the full
// original lib is imported

// COMMON JS Components
//import './components/common/book-now/v1/js/book-now.js';
import './components/common/quick-access/v2/js/quick-access-v2.js';
import './components/common/core-booking-engine/v1/js/core-booking-engine.js';
import './components/common/social-media/v1/js/social-media.js';
import './components/common/sticky-booking-engine/v1/sticky-booking-engine/js/sticky-booking-engine.js';
import './components/common/faq/v1/js/faq.js';
import './components/common/loyalty/v1/js/loyalty.js';
import './components/common/callouts/v1/callout/js/callout.js';
import './components/common/callouts/v1/callout-container/js/callout-container.js';
import './components/common/subscription-block/v1/js/subscription-block.js';
import './components/common/heading/v1/js/heading.js';

// CONTENT JS Components
import './components/content/billboard/v1/js/billboard.js';
import './components/content/list/v2/js/list-v2.js';


// STRUCTURE JS Components
import './components/structure/base-page-template/v1/js/base-page-template.js';
import './components/content/slideshow-carousel/v1/js/slideshow-carousel.js';

// Form JS Components
import './components/form/chip/v1/js/chip.js'

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
