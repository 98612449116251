/**
 * @author Rishabh
 */
import { BasePageTemplate, TagManager } from '@accor/ace-ui-core';

export default class IBISBasePageTemplate extends BasePageTemplate {
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
    this.basePageTagManagerPush();

    // add connected class if user logged in
    if (CoreJS.CommonUtils.userLoginStatus()) {
      document.body?.classList?.add('login-connected');
    } else {
        document.body?.classList?.remove('login-connected')
    }
  }

  /**
   * Pushing tagmanager data on various components as per requirement (components who do not have js classes registered)
   */
  basePageTagManagerPush() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    // For loyalty billboard tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::brand::homepage' ||
     this.dataLayerJson?.pageName === 'ibis::magazine::hub' || this.dataLayerJson?.pageName === 'ibis::magazine::category' || this.dataLayerJson?.pageName === 'ibis::magazine::article' || this.dataLayerJson?.pageName?.split('::')[1] === 'go get it') {
      document?.querySelectorAll('.billboard .ace-billboard__fullgrid .ace-billboard__fullgrid__button .ace-button a').forEach(( button ) => {
        button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          this.pushBillboardLoyaltyTagmanager();
        });
      });
    }

    // For Highlight tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::magazine::hub' || this.dataLayerJson?.pageName === 'ibis::offers::hub' || this.dataLayerJson?.pageName?.split('::')[1] === 'go get it') {
      document?.querySelectorAll('.highlight .ace-highlight .ace-highlight__content .ace-button a').forEach(( button ) => {
        button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          this.pushHighlightTagmanager(event);
        });
      });
    }

    // For Paragraph tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::offers::details') {
      document?.querySelectorAll('.paragraph .ace-paragraph .ace-paragraph__text .ace-button a').forEach(( button ) => {
        button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          this.pushParagraphTagmanager(event);
        });
      });
    }

    // For Article cta tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::magazine::article') {
      document?.querySelectorAll('[class*="article-align-"] .call-to-action .call-to-action .ace-button a').forEach(( button ) => {
        button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          this.pushArticleCtaTagmanager();
        });
      });

      document?.querySelectorAll('.sharepage ul li .share__link').forEach(( anchor ) => {
        anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, ( event ) => {
          const classListItems = event?.target?.classList?.value?.split('--')[1];
          let shareElement = classListItems;
          if (classListItems === 'button') {
            shareElement = 'link';
          }
          this.pushArticleShareCtaTagmanager(shareElement);
        });
      });
    }

    // For hub tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::services' || this.dataLayerJson?.pageName?.split('::')[1] === 'go get it') { // Service template and ibis go get it
      document?.querySelectorAll('.ace-hub .ace-hub__content__link').forEach(( anchor ) => {
        anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, ( event ) => {
          this.pushHubCtaTagmanager(event);
        });
      });
    }

    // For heading-hero cta tag manager push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::destinations::city') { // Destination City
        document?.querySelectorAll('.heading-hero .ace-headingpagehero .cmp-headingpagehero__content .cmp-headingpagehero-btn-group__item a').forEach(( anchor ) => {
          anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            this.pushHeadingHeroCtaTagmanager();
          });
        });
      }
    // For loyalty push on various templates
    if (this.dataLayerJson?.pageName === 'ibis::brand::homepage') { // Homepage template
        document?.querySelectorAll('.ace-loyalty .ace-loyalty__inner .ace-loyalty__button .ace-loyalty__btn').forEach(( anchor ) => {
            anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            this.pushLoyaltyTagmanager();
            });
        });
    }

    // For CTA tagmanager push on Loyalty page
    if (this.dataLayerJson?.pageName === 'ibis::loyalty') { // Loyalty template
        document?.querySelectorAll('.call-to-action .call-to-action .ace-button a')?.forEach(( anchor, index ) => {
            anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            this.pushCTATagmanager(event, index);
            });
        });
    }
}

  /**
   * Pushing loyalty billboard data to tagmanager
   */
  pushBillboardLoyaltyTagmanager() {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: 'loyalty',
        bloc_interaction: 'become member'
      }
    };
    if (this.dataLayerJson?.pageName === 'ibis::magazine::hub') {
      dataEvent.event_data.bloc_name = 'all program';
    }
    if (this.dataLayerJson?.pageName === 'ibis::magazine::category') {
      dataEvent.event_data.bloc_name = document.querySelector('.heading .ace-headingpagehero-headline [class*="expressive-heading"]')?.innerText?.trim()?.toLowerCase();
      dataEvent.event_data.bloc_type = 'become a member';
      dataEvent.event_data.bloc_interaction = 'all program';
    }
    if (this.dataLayerJson?.pageName === 'ibis::magazine::article') {
      dataEvent.event_data.bloc_name = document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase();
      dataEvent.event_data.bloc_type = 'become a member';
      dataEvent.event_data.bloc_interaction = 'all program';
    }
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Paragraph data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
  pushParagraphTagmanager(event) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: document.querySelector('.heading .ace-headingpagehero-headline [class*="expressive-heading"]')?.innerText?.trim()?.toLowerCase(),
        bloc_interaction: 'offer detail',
        bloc_type: event?.target?.innerText?.trim()?.toLowerCase()
      }
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Article cta data to tagmanager
   */
  pushArticleCtaTagmanager() {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase(),
        bloc_interaction: 'check rates'
      }
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Highlight cta data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
  pushHighlightTagmanager(event) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: event?.target?.closest('.ace-highlight__content')?.querySelector('.ace-highlight__content--title')?.innerText?.trim()?.toLowerCase(),
        bloc_interaction: event?.target?.querySelector('.cmp-button__text')?.innerText?.trim()?.toLowerCase()
      }
    };
    if (this.dataLayerJson?.pageName === 'ibis::offers::hub') {
      dataEvent.event_data.bloc_name = 'offer of the month';
      dataEvent.event_data.bloc_interaction = 'apply this offer';
    } else if (this.dataLayerJson?.pageName?.split('::')[1] === 'go get it') {
        dataEvent.event_data.bloc_name = 'limitless experience contest';
        dataEvent.event_data.bloc_interaction = 'see dedicated page';
    }
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Share component cta data to tagmanager
   * @param {String} shareElement cta type
   */
  pushArticleShareCtaTagmanager(shareElement) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase(),
        bloc_interaction: `share ${shareElement}`
      }
    };
    if (this.dataLayerJson?.pageName === 'ibis::offers::hub') {
      dataEvent.event_data.bloc_name = 'offer of the month';
      dataEvent.event_data.bloc_interaction = 'apply this offer';
    }
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Hub cta data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
  pushHubCtaTagmanager(event) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_interaction: event?.target?.innerText?.trim()?.toLowerCase(),
        bloc_name: event?.target?.closest('.ace-hub')?.querySelector('.ace-hub__title__main')?.innerText?.trim()?.toLowerCase(),
      }
    };
    if (this.dataLayerJson?.pageName !== 'ibis::services') {
      dataEvent.event_data.bloc_name = 'heartist promise';
      dataEvent.event_data.bloc_interaction = 'see ibis corporate video';
    }
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Article cta data to tagmanager
   */
  pushHeadingHeroCtaTagmanager() {
    const urlForCity = window.location.pathname?.split('/');
    const cityName = urlForCity[urlForCity?.length - 1]?.split('-')[1];
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: `${cityName} book`
      }
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Loyalty data to tagmanager
   */
  pushLoyaltyTagmanager() {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_interaction: 'become member',
        bloc_name: 'loyalty footer',
      }
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing cta data to tagmanager
   * @param {HTMLElement} event The target CTA where action performed
   * @param {number} index The target CTA index number
   */
  pushCTATagmanager(event, index) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'cta_enroll',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        enroll_context: 'loyalty',
        cta_name: event?.target?.innerText?.trim()?.toLowerCase(),
        contexte: index + 1
      }
    };
    TagManager.trackEvent(dataEvent);
  }
}

CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, IBISBasePageTemplate, true);
