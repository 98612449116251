/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Billboard Component instance
 * @author Rishabh
 * @class IbisBillboard
 * @classdesc Ibis billboard component to inherit the billboard core component
 * @extends Billboard
 * @version 1.0
 */
import { Billboard, TagManager } from '@accor/ace-ui-core';

export default class IbisBillboard extends Billboard {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.billBoardCta = this.componentHost.querySelectorAll('.ace-billboard__fullwidth__button a');
    this.tagManagerHandler();

    //remove hide for with image variation
    if (this.componentHost?.querySelector('.ace-billboard__withimage')) {
      this.componentHost?.classList?.add('show');
    }
  }

  tagManagerHandler() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.billBoardCta.forEach((element) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: 'content bloc',
              bloc_interaction: element?.querySelector('span')?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }
}


// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Billboard.CLASS_NAMESPACE, IbisBillboard, true);
