/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Faq Component instance
 * @author Rishabh
 * @class IbisFaq
 * @classdesc Ibis Faq component to inherit the Faq core component
 * @extends Faq
 * @version 1.0
 */
import { Faq, TagManager } from '@accor/ace-ui-core';

export default class IbisFaq extends Faq {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }
    this.dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: 'see all questions'
      }
    };
    this.faqCta = this.componentHost.querySelector('.cmp-accordion__more-button a');
    this.TagManagerHandler();
  }

  /**
     * Push data in tagmanager
     */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.faqCta?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        TagManager.trackEvent(this.dataEvent);
      });
    }
  }

/**
     * overwriting from core and setting empty
     */
  setFaqClickListener() {

  }
}


// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Faq.CLASS_NAMESPACE, IbisFaq, true);
